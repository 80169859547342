<template>
  <div class="content">
    <NavBar :title="$t('shop.order.title')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </NavBar>
    <div class="wrap" :style="{ minHeight:'100vh'}">
      <div class="warp_content">
        <List url="/marketing/cart" ref="list" listWrapClass="list_bottom">
          <template #item="{ data }">
            <div class="list_item">
              <div class="item" :class="{ flex_reverse: lan === 'ar' }">
                <div class="item_label" :class="{ arSet: lan === 'ar' }">{{ lan == 'ar' ? ':' : '' }}{{
      $t('shop.order.orderNo') }}{{ lan != 'ar' ? ':' : '' }}</div>
                <div class="item_value">{{ data.order_no }}</div>
              </div>
              <div class="item" :class="{ flex_reverse: lan === 'ar' }">
                <div class="item_label" :class="{ arSet: lan === 'ar' }">{{ lan == 'ar' ? ':' : '' }}{{ $t('shop.order.link')
                  }} {{ lan != 'ar' ? ':' : '' }}</div>
                <div class="item_value">{{ data.item.url }}</div>
              </div>
              <div class="item" :class="{ flex_reverse: lan === 'ar' }">
                <div class="item_label" :class="{ arSet: lan === 'ar' }">{{ lan == 'ar' ? ':' : '' }}{{
      $t('shop.order.screenshot') }}{{ lan != 'ar' ? ':' : '' }}</div>
                <div class="item_imgs">
                  <div class="img" v-for="(val, index) in data.item.image">
                    <img :src="$img(val)" alt="">
                  </div>
                </div>
              </div>
            </div>
          </template>
        </List>
      </div>
    </div>
  </div>
</template>

<script>
import { NavBar, Icon } from "vant";
import List from "@/components/List.vue";
export default {
  components: {
    NavBar,
    [Icon.name]: Icon,
    List
  },
  data() {
    return {

    }
  },
  methods: {

  },
  created() {

  }
}
</script>
<style scoped lang="less">
@Color: var(--theme);
@gap: calc(12rem / 16);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.content {
  height: 100vh;
  overflow: hidden;
  
  .wrap {
    // height: calc(100vh - (50rem / 16) - (49rem / 16) - (38rem / 16) - (90rem / 16));
    background-color: var(--bg);
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 1000px;

    .warp_content {
      margin: @gap;

      :deep(.list_bottom) {
        margin-bottom: @gap;
      }
    }

    .list_item {
      background-color: #f7f8fc;
      padding: calc(@gap / 2) @gap;
      border-radius: calc(@gap / 2);

      .flex_reverse {
        flex-direction: row-reverse;
      }

      .item {
        display: flex;
        padding: calc(@gap / 2) 0;

        &_label {
          width: calc(80rem / 16);
          font-size: calc(16rem / 16);
          display: flex;
          margin-left: 0;
          margin-right: @gap;
        }

        .arSet {
          justify-content: flex-end;
          margin-left: @gap;
          margin-right: 0;
        }

        &_value {
          font-size: calc(16rem / 16);
          display: flex;
          align-items: center;
        }

        &_imgs {
          display: flex;
          flex-wrap: wrap;
          flex: 1;
          @imgWidth: calc(calc(100% - @gap * 2) / 2);

          .img {
            width: @imgWidth;
            // height: @imgWidth;
            overflow: hidden;
            border-radius: calc(5rem / 16);
            margin-right: @gap;
            margin-bottom: @gap;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
</style>